
import { Contract, ContractHistory } from '@/interfaces'

import Vue, { PropType } from 'vue'

export default Vue.extend({
    props: {
        contract: {
            type: Object as PropType<Contract>,
            required: true,
        },
    },
    computed: {
        contractHistories(): ContractHistory[] {
            return this.contract.contract_histories.filter(item => {
                return item.status === '契約中'
            });
        }
    }
})
