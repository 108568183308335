
import http from '@/http';
import { Building, Contract } from '@/interfaces'
import Vue, { PropType } from 'vue'

import MyContract from "@/components/MyContract.vue";

export interface DataType {
    data: Contract[];
}

export default Vue.extend({
    components: {
        MyContract
    },
    data(): DataType {
        return {
            data: [],
        };
    },
    props: {
        building: {
            type: Object as PropType<Building>,
            required: true,
        },
    },
    computed: {
        contracts(): Contract[] {
            return this.data.filter(contract => {
                return contract.contract_histories.every(item => {
                    if (item.status === '解約完了') {
                        return false;
                    }
                    return true
                })
            })
        }
    },
    async mounted() {
        const url = `buildings/${this.building.id}/contracts`;

        const { data } = await http.get<Contract[]>(url);

        this.data = data;
    },
    methods: {
        hasMember(contract: Contract) {
            const hasMember = contract.contract_histories.some(item => {
                if (item.status !== '契約中') {
                    return false;
                }

                if (item.contractor) {
                    return !!item.contractor.user_id
                }
                if (item.resident) {
                    return !!item.resident.user_id
                }
                return false;
            })
            return hasMember ? 'primary' : '';
        }
    }
})
